@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.office {
  background-color: aquamarine;
  background-image: url('./images/flexi_office_mk_opt.webp');
  background-size: cover;
  background-repeat: no-repeat;
  height: 30rem;
}

.z-50 {
  z-index: 50;
  top: 38px
}

/* Define the keyframes for the zoom effect */
@keyframeshhj zoom {
  0% {
    background-size: 100%;
  }
  50% {
    background-size: 110%; /* Zoom in */
  }
  100% {
    background-size: 100%; /* Zoom out */
  }
}

/* Apply the animation to the background */
.zoom-background {
  animation: zoom 10s infinite; /* Change duration as needed */
  background-image: url('./images/FlexiHomes_white_bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Optional for parallax effect */
}
